<template>
  <div>
    <b-row class="match-height-container">
      <template v-if="this.businessType[0] == 'leadgen'">
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.costMicrosData" title="Spends" :summary="summaryData.costMicros" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.impressionsData" title="Impressions" :summary="summaryData.impressions" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.clicksData" title="Clicks" :summary="summaryData.clicks" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.cpclData" title="CPC" :summary="summaryData.cpcl" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.ctrData" title="CTR" :summary="summaryData.ctr" /> 
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.conversionData" title="Leads" :summary="summaryData.conversions" />
        </b-col>
        <!-- <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="
            chartDataValues.labels.length && this.businessType == 'leadgen'
          " :labels="chartDataValues.labels" :data="chartDataValues.conversionData" title="No. of Leads"
            :summary="summaryData.conversions" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.cpcData" title="CAC" :summary="summaryData.cpc" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.conversionRateData" title="Conversion Rate" :summary="summaryData.conversionRate" />
        </b-col> -->
      </template>
      <template v-else-if="this.businessType[0] == 'leadform'">
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.costMicrosData" title="Spends" :summary="summaryData.costMicros" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.impressionsData" title="Impressions" :summary="summaryData.impressions" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.clicksData" title="Clicks" :summary="summaryData.clicks" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.cpclData" title="CPC" :summary="summaryData.cpcl" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.ctrData" title="CTR" :summary="summaryData.ctr" /> 
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.conversionData" title="Leads" :summary="summaryData.conversions" />
        </b-col>
        <!-- <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="
            chartDataValues.labels.length && this.businessType == 'leadgen'
          " :labels="chartDataValues.labels" :data="chartDataValues.conversionData" title="No. of Leads"
            :summary="summaryData.conversions" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.cpcData" title="CAC" :summary="summaryData.cpc" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.conversionRateData" title="Conversion Rate" :summary="summaryData.conversionRate" />
        </b-col> -->
      </template>
      <template v-else>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.costMicrosData" title="Spends" :summary="summaryData.costMicros" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.impressionsData" title="Impressions" :summary="summaryData.impressions" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.clicksData" title="Clicks" :summary="summaryData.clicks" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.cpclData" title="CPC" :summary="summaryData.cpcl" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.ctrData" title="CTR" :summary="summaryData.ctr" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.cpmData" title="CPM" :summary="summaryData.avg_cpm" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <div v-if="this.businessType[0] == 'ecommerce'">
            <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.conversionData" title="Purchases" :summary="summaryData.conversions" />
          </div>
          <div v-else>
            <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.conversionData" title="Conversions" :summary="summaryData.conversions" />
            </div>
        </b-col>
        <!-- <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart :labels="chartDataValues.labels" :data="chartDataValues.revenueData"
            title="Revenue" :summary="summaryData.allConversionsValue" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.cpcData" title="CAC" :summary="summaryData.cpc" />
        </b-col>
        <b-col lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.conversionRateData" title="Conversion Rate" :summary="summaryData.conversionRate" />
        </b-col> -->
      </template>
      <b-col cols="4" class="chartContainer" v-if="this.businessType[0] !== 'leadgen' && this.businessType[0] !== 'leadform'">
        <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
          :data="chartDataValues.roasData" title="ROAS" :summary="summaryData.ROAS" />
      </b-col>
      <template v-if="this.businessType == 'leadgen'">
        <b-col cols="4" class="chartContainer">
        <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
          :data="chartDataValues.cplData" title="CPL" :summary="summaryData.cpc" />
      </b-col>
      <b-col v-if="this.businessType[0] !== 'leadgen' && this.businessType[0] !== 'leadform' " lg="4" cols="4" class="chartContainer">
          <card-statistic-profit-chart v-if="chartDataValues.labels.length" :labels="chartDataValues.labels"
            :data="chartDataValues.cpmData" title="CPM" :summary="summaryData.avg_cpm" />
        </b-col>
      </template>
    </b-row>
    <!-- <b-row class="match-height">
      <b-col lg="8" cols="12" class="chartContainer">
        <card-statistics-group :summaryData="summaryData" :iconType="iconCardType" :businessType="businessType"
          v-if="chartDataValues.labels.length" />
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import CardStatisticsGroup from "./CardStatisticsGroup.vue";
import CardStatisticProfitChart from "./CardStatisticProfitChart.vue";

export default {
  props: {
    client:{
      type:Object
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    iconCardType: { type: String },
    summaryData: {
      type: Object,
      default: () => {
        return {
          ROAS: 0,
          allConversionsValue: 0,
          avg_cpm:0,
          clicks:0,
          conversionRate:0,
          conversions:0,
          conversionsValue:0,
          costMicros:0,
          costPerConversion:0,
          cpc:0,
          cpcl:0,
          ctr:0,
          impressions:0,
        };
      },
    },
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    businessType: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    return {
      iconType: 'Hello'
    };
  },
  computed: {
    chartDataValues() {
      let basedOnbusinessType = ""
      if (this.businessType[0] == "leadgen") {
        basedOnbusinessType = "Leads"
      }else if (this.businessType[0] == "ecommerce") {
        basedOnbusinessType = "Purchases"
      }else{
        basedOnbusinessType = "Conversions"
      }
      let data = this.chartData;
      let labels = [];
      let clicksData = [{ name: "Clicks", data: [] }];
      let impressionsData = [{ name: "Impressions", data: [] }];
      let revenueData = [{ name: "Revenue", data: [] }];
      let cpclData = [{ name: "CPC", data: [] }];
      let conversionRateData = [{ name: "Conversion Rate", data: [] }];
      let conversionData = [{ name: basedOnbusinessType, data: [] }];
      let ctrData = [{ name: "CTR", data: [] }];
      let costMicrosData = [{ name: "Spends", data: [] }];
      let cplData = [{ name: "CPL", data: [] }];
      let roasData = [{ name: "ROAS", data: [] }];
      let cpmData = [{ name: "CPM", data: [] }];

      for (let i = 0; i < data.length; i++) {
        const dataObject = data[i];
        labels.push(dataObject.date);
        clicksData[0].data.push(dataObject.clicks);
        impressionsData[0].data.push(dataObject.impressions);
        revenueData[0].data.push(dataObject.allConversionsValue);
        cpclData[0].data.push(dataObject.cpcl);
        conversionRateData[0].data.push(dataObject.conversionRate);
        conversionData[0].data.push(dataObject.conversions);
        ctrData[0].data.push(dataObject.ctr);
        costMicrosData[0].data.push(dataObject.costMicros);
        cplData[0].data.push(dataObject.cpc);
        roasData[0].data.push(dataObject.ROAS);
        cpmData[0].data.push(dataObject.avg_cpm)
      }
      return {
        labels,
        clicksData,
        impressionsData,
        revenueData,
        cpclData,
        conversionRateData,
        conversionData,
        ctrData,
        costMicrosData,
        cplData,
        roasData,
        cpmData
      };
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    CardStatisticsGroup,
    CardStatisticProfitChart,
  },
};
</script>
<style>
.chartContainer {
  padding: 6px !important;
}
</style>
